import queryString from 'query-string'
import React, { useEffect, useState } from 'react'

const loopArray = (length, index) => {
  if (index > length) {
    return index - length - 1
  } else if (index < 0) {
    return index + length + 1
  } else {
    return index
  }
}

function useSelectedColor(
  searchQuery,
  defaultSelected,
  colors,
  oosData,
  offSet = 0
) {
  const [selectedColor, setSelectedColor] = useState(defaultSelected)
  const [oosPrev, setOosPrev] = useState([])
  useEffect(() => {
    const search = queryString.parse(searchQuery)
    const keys = Object.keys(search)

    keys.map((val) => {
      if (colors.includes(val)) {
        setSelectedColor(colors.indexOf(val))
      }
      return undefined
    })
  }, [])

  useEffect(() => {
    let colorHasBeenSelected = false
    setOosPrev(oosData)
    if (JSON.stringify(oosData) !== JSON.stringify(oosPrev)) {
      const search = queryString.parse(searchQuery)
      const keys = Object.keys(search)

      let selectedIndexFromParams = undefined
      keys.map((val) => {
        if (colors.includes(val)) {
          selectedIndexFromParams = colors.indexOf(val)
        }
      })

      if (oosData && typeof oosData === 'object' && oosData.length > 0) {
        if (
          selectedIndexFromParams !== undefined &&
          oosData[selectedIndexFromParams] !== undefined &&
          oosData[selectedIndexFromParams].on_site === true &&
          oosData[selectedIndexFromParams].avaliable === true
        ) {
          setSelectedColor(selectedIndexFromParams)
        } else {
          let index = 0
          oosData.map((value, key) => {
            if (
              !colorHasBeenSelected &&
              value.on_site === true &&
              value.avaliable === true
            ) {
              index = key
              colorHasBeenSelected = true
            }
          })
          setSelectedColor(loopArray(oosData.length - 1, index + offSet))
        }
      }
    }
  }, [oosData])

  return [selectedColor, setSelectedColor]
}

export default useSelectedColor
